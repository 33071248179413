import React, { useEffect, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Accordion, Button } from 'react-bootstrap'
import Scrollbar from 'smooth-scrollbar'
import { connect } from "react-redux";
import { getDarkMode } from '../../../../store/mode'
import { logoutUser } from '../../../../store/actions';

//img
import logo from '../../../../assets/images/logo.png'

function mapStateToProps(state) {
    return {
        darkMode: getDarkMode(state)
    };
}


const minisidbar = () => {
    document.body.classList.toggle('sidebar-main')
}


const SidebarStyle = (props) => {
    const user = props.user.user.user.userModel;
    //location
    let location = useLocation();
    let history = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const sidebar = urlParams.get('sidebar');
    var variant = '';
    if (sidebar !== null) {
        variant = '';
        switch (sidebar) {
            case "0":
                variant = 'sidebar-dark';
                break;
            case "1":
                variant = 'sidebar-light';
                break;
            default:
                variant = '';
                break;
        }
    }
    const sidebarStyle = {
        // backgroundColor: props.user.user.companyDetails.color_them
        backgroundColor: '#000'
    };
    // Collapse state
    const [activeMenu, setActiveMenu] = useState(false)
    const [activesubMenu, setSubmenu] = useState(false)
    useEffect(
        () => {
            Scrollbar.init(document.querySelector('#my-scrollbar'))
        }
    )

    const handleDelayedNavigation = (to) => {
        setTimeout(() => {
            history.push(to)
        }, 0); // Delay in milliseconds
    };

    const handleLogout = () => {
        props.logoutUser(); // Dispatch logout action
        // Redirect user to login page after logout
        history.push('/');
    };

    return (
        <>
            <div className={`iq-sidebar sidebar-default ${variant}`} style={sidebarStyle}>
                <div className="iq-sidebar-logo d-flex align-items-end justify-content-between">
                    <Link to="/" className="header-logo">
                        <img src={logo} className={`img-fluid rounded-normal light-logo ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                        {/* <span>{props.user.user.companyDetails.name}</span> */}
                    </Link>
                    <div className="side-menu-bt-sidebar-1">
                        <svg onClick={minisidbar} xmlns="http://www.w3.org/2000/svg" className="text-light wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div className="data-scrollbar" data-scroll="1" id="my-scrollbar">
                    <nav className="iq-sidebar-menu">
                        <Accordion as="ul" id="iq-sidebar-toggle" className="side-menu" onSelect={(e) => setActiveMenu(e)}>
                            <li className={`${location.pathname === '/' ? 'active' : ''}  sidebar-layout`}>
                                <Link to="/dashboard" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Dashboard</span>
                                </Link>
                            </li>

                            <li className="px-3 pt-3 pb-2 ">
                                <span className="text-uppercase small font-weight-bold">Application</span>
                            </li>

                            {/* Admin Area Navigation */}
                            {user?.is_admin ? (
                                <li className={`${location.pathname === '/users' ? 'active' : ''}  sidebar-layout`}>
                                    <Link to="/users" className="svg-icon ">
                                        <i className="">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                            </svg>
                                        </i>
                                        <span className="ml-2">Users</span>
                                    </Link>
                                </li>
                            ) : null}

                            {user?.is_admin ? (
                                <li className={`${location.pathname === '/pending-users' ? 'active' : ''}  sidebar-layout`} >
                                    <Link to="/pending-users" className="svg-icon">
                                        <i className="">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </i>
                                        <span className="ml-2">Pending Users</span>
                                    </Link>
                                </li>
                            ) : null}

                            {user?.is_admin ? (
                                <li className={`${location.pathname === '/recharge-request' ? 'active' : ''}  sidebar-layout`} >
                                    <Link to="/recharge-request" className="svg-icon">
                                        <i className="">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </i>
                                        <span className="ml-2">Recharge Request</span>
                                    </Link>
                                </li>
                            ) : null}

                            {user?.is_admin ? (
                                <li className={`${location.pathname === '/transactions' ? 'active' : ''}  sidebar-layout`} >
                                    <Link to="/transactions" className="svg-icon">
                                        <i className="">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </i>
                                        <span className="ml-2">Recharge Transactions</span>
                                    </Link>
                                </li>
                            ) : null}

                            {user?.is_admin ? (
                                <li className={`${location.pathname === '/routing-and-pricelist' ? 'active' : ''}  sidebar-layout`}>

                                    <Link to="/routing-and-pricelist" className="svg-icon">
                                        <i className="">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 1.5c3.59 0 6.5 2.91 6.5 6.5s-2.91 6.5-6.5 6.5-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5zM9 11v2m0 0v2m0-2h2m-2 0h2m10-5a2 2 0 100 4 2 2 0 000-4z" />
                                            </svg>
                                        </i><span className="ml-2">Routing and Price List</span>
                                    </Link>
                                </li>
                            ) : null}

                            {/* Reseller Area Navigation */}
                            {!user?.is_admin ? (
                                <li className={`${location.pathname === '/account-settings' ? 'active' : ''}  sidebar-layout`}>
                                    <Link to="/account-settings" className="svg-icon">
                                        <i className="">
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                                            </svg> */}
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                            </svg>
                                        </i><span className="ml-2">Account Settings</span>
                                    </Link>
                                </li>
                            ) : null}

                            {!user?.is_admin ? (
                                <li className={`${location.pathname === '/recharge-wallet' ? 'active' : ''}  sidebar-layout`}>

                                    <Link to="/recharge-wallet" className="svg-icon">
                                        <i className="">
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 1.5c3.59 0 6.5 2.91 6.5 6.5s-2.91 6.5-6.5 6.5-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5zM9 11v2m0 0v2m0-2h2m-2 0h2m10-5a2 2 0 100 4 2 2 0 000-4z" />
                                            </svg> */}
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                                            </svg>


                                        </i><span className="ml-2">Wallet</span>
                                    </Link>
                                </li>
                            ) : null}

                            {!user?.is_admin ? (
                                <li className={`${location.pathname === '/top-up' ? 'active' : ''}  sidebar-layout`}>

                                    <Link to="/top-up" className="svg-icon">
                                        <i className="">
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 1.5c3.59 0 6.5 2.91 6.5 6.5s-2.91 6.5-6.5 6.5-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5zM9 11v2m0 0v2m0-2h2m-2 0h2m10-5a2 2 0 100 4 2 2 0 000-4z" />
                                            </svg> */}
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                            </svg>




                                        </i><span className="ml-2">Top Up</span>
                                    </Link>
                                </li>
                            ) : null}

                            {/* {!user.is_admin ? (
                                <li className={`${location.pathname === '/products' ? 'active' : ''}  sidebar-layout`}>
                                    <Link to="/products" className="svg-icon">
                                        <i className="">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
                                            </svg>
                                        </i><span className="ml-2">Products</span>
                                    </Link>
                                </li>
                            ) : null} */}

                            <li className={`${location.pathname === '/topup-transactions' ? 'active' : ''}  sidebar-layout`}>
                                <Link to="/topup-transactions" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                                        </svg>
                                    </i><span className="ml-2">Top Up Transactions</span>
                                </Link>
                            </li>

                            {/* {user.is_admin ? (
                                <li className={`${location.pathname === '/operators' ? 'active' : ''}  sidebar-layout`}>

                                    <Link to="/operators" className="svg-icon">
                                        <i className="">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 1.5c3.59 0 6.5 2.91 6.5 6.5s-2.91 6.5-6.5 6.5-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5zM9 11v2m0 0v2m0-2h2m-2 0h2m10-5a2 2 0 100 4 2 2 0 000-4z" />
                                            </svg>

                                        </i><span className="ml-2">Operators</span>
                                    </Link>
                                </li>
                            ) : null} */}

                            {user?.is_admin ? (
                                <li className={`${location.pathname === '/vendors' ? 'active' : ''}  sidebar-layout`}>

                                    <Link to="/vendors" className="svg-icon">
                                        <i className="">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 1.5c3.59 0 6.5 2.91 6.5 6.5s-2.91 6.5-6.5 6.5-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5zM9 11v2m0 0v2m0-2h2m-2 0h2m10-5a2 2 0 100 4 2 2 0 000-4z" />
                                            </svg>

                                        </i><span className="ml-2">Vendors</span>
                                    </Link>
                                </li>
                            ) : null}

                            <li className={`${location.pathname === '/support-info' ? 'active' : ''}  sidebar-layout`}>
                                <Link to="/support-info" className="svg-icon">
                                    <i className="">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 1.5c3.59 0 6.5 2.91 6.5 6.5s-2.91 6.5-6.5 6.5-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5zM9 11v2m0 0v2m0-2h2m-2 0h2m10-5a2 2 0 100 4 2 2 0 000-4z" />
                                        </svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                        </svg>

                                    </i><span className="ml-2">Info / Support</span>
                                </Link>
                            </li>

                            <li className='sidebar-layout'>
                                <a href="#" onClick={handleLogout} className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                        </svg>
                                    </i><span className="ml-2">Logout</span>
                                </a>
                            </li>
                        </Accordion>
                    </nav>
                    <div className="pt-5 pb-5"></div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = {
    logoutUser,
};

// export default SidebarStyle;
export default connect(mapStateToProps, mapDispatchToProps)(SidebarStyle)