import { Switch, Route, useLocation } from 'react-router-dom'
import React, { useEffect } from 'react';

//main
import Dashbord from '../views/backend/Main/Dashbord'

// Customers
import Customer from '../views/backend/Main/Customer';
import Customeradd from '../views/backend/Main/Customeradd';
import Customerview from '../views/backend/Main/Cutomerview';
import Customeredit from '../views/backend/Main/Customeredit';

// order
import Order from '../views/backend/Main/Order';
import Ordernew from '../views/backend/Main/Ordernew';
import Orderdetails from '../views/backend/Main/Orderdetails';
import Orderedit from '../views/backend/Main/Orderedit';

//App
import UserProfile from '../views/backend/App/UserManagement/UserProfile'
import UserAdd from '../views/backend/App/UserManagement/UserAdd'
import UserList from '../views/backend/App/UserManagement/UserList'
import UserProfileEdit from '../views/backend/App/UserManagement/UserProfileEdit'

//Chart
import ApexChart from '../views/backend/Chart/ApexChart'
import Invoice from '../views/backend/pages/Invoice'
import Invoiceview from '../views/backend/pages/Invoiceview';

//Companies
import Companies from '../views/backend/Main/Companies';
import Companiesedit from '../views/backend/Main/Companiesedit';
import Companiesadd from '../views/backend/Main/Companiesadd';
import Companiesview from '../views/backend/Main/Companiesview';

//Categories
import Categories from '../views/backend/Main/Categories';
import Categoriesadd from '../views/backend/Main/Categoriesadd';
import Categoriesedit from '../views/backend/Main/Categoriesedit';


//Transactions
import Transaction from '../views/backend/Main/Transaction';

//Users
import Users from '../views/backend/Main/Users';
import PendingUsers from '../views/backend/Main/PendingUsers';
import ResellerProfile from '../views/backend/Main/ResellerProfile';
import RechargeWallet from '../views/backend/Main/RechargeWallet';
import RechargeRequest from '../views/backend/Main/RechargeRequest';
import WalletView from '../views/backend/Main/WalletView';
import UserDetails from '../views/backend/Main/UserDetails';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../store/actions';
import TopUp from '../views/backend/Main/TopUp';
import Commissions from '../views/backend/Main/Commissions';
import Vendors from '../views/backend/Main/Vendors';
import TopUpTransactions from '../views/backend/Main/TopUpTransactions';
import TopUpInvoice from '../views/backend/Main/TopUpInvoice';
import AdminAccountSettings from '../views/backend/Main/AdminAccountSettings';
import SupportInfo from '../views/backend/Main/SupportInfo';
import SupportInfoForReseller from '../views/backend/Main/SupportInfoForReseller';

const Layout1Route = (user) => {
    let location = useLocation();

    const dispatch = useDispatch();

    useEffect(() => {
        checkAuthentication();
    }, []);

    const checkAuthentication = () => {
        const token = user.user.user.token;

        const headers = {
            Authorization: `Bearer ${token}`
        };

        axios.get(process.env.REACT_APP_API_URL + '/check-authentication', { headers })
            .then((res) => {
            })
            .catch((err) => {
                console.log(err, "errerr");
                if (err.response.status === 401) {
                    dispatch(logoutUser());
                }
            });
    }

    return (
        <Switch location={location}>

            <Route path="/dashboard" exact render={(props) => <Dashbord {...props} user={user} checkAuthentication={checkAuthentication} />} />
            <Route path="/" exact render={(props) => <Dashbord {...props} user={user} />} />

            {/* App */}
            <Route path="/user-profile" render={(props) => <UserProfile {...props} user={user} />} />

            <Route path="/user-add" component={UserAdd} />
            <Route path="/user-list" component={UserList} />
            <Route path="/user-profile-edit" render={(props) => <UserProfileEdit {...props} user={user} />} />

            {/* Chart */}
            <Route path="/chart-apex" component={ApexChart} />

            {/* Extrapages */}
            <Route path="/invoices" render={(props) => <Invoice {...props} user={user} />} />
            <Route path="/invoices-view/:invoiceId"><Invoiceview user={user} /></Route>

            {/*Customer*/}
            <Route path="/customers" render={(props) => <Customer {...props} user={user} />} />
            <Route path="/customers-add" render={(props) => <Customeradd {...props} user={user} />} />
            <Route path="/customers-view/:customerId" render={(props) => <Customerview {...props} user={user} />} />
            <Route path="/customers-edit/:customerId"><Customeredit user={user} /></Route>

            {/* Categories */}
            <Route path="/categories" render={(props) => <Categories {...props} user={user} />} />
            <Route path="/categories-add" render={(props) => <Categoriesadd {...props} user={user} />} />
            <Route path="/categories-edit/:categoryId"><Categoriesedit user={user} /></Route>

            {/* Order */}
            <Route path="/orders" render={(props) => <Order {...props} user={user} />} />
            <Route path="/orders-new" render={(props) => <Ordernew {...props} user={user} />} />
            <Route path="/orders-details/:orderId" render={(props) => <Orderdetails {...props} user={user} />} />
            <Route path="/orders-edit/:orderId" render={(props) => <Orderedit {...props} user={user} />} />

            {/* Transactions */}
            <Route path="/transactions" render={(props) => <Transaction {...props} user={user} />} />

            {/* Companies */}

            <Route path="/companies" render={(props) => <Companies {...props} user={user} />} />
            <Route path="/company-edit/:companyId"><Companiesedit user={user} /></Route>
            <Route path="/companies-add" render={(props) => <Companiesadd {...props} user={user} />} />
            <Route path="/company-view/:companyId"><Companiesview user={user} /></Route>

            {/* Reseller Profile Settings Page */}
            {user?.user?.user?.user?.userModel?.is_admin ?
                <Route path="/account-settings" render={(props) => <AdminAccountSettings {...props} user={user} />} /> :
                <Route path="/account-settings" render={(props) => <ResellerProfile {...props} user={user} />} />
            }

            {/* Reseller wallet recharge */}
            <Route path="/recharge-wallet" render={(props) => <RechargeWallet {...props} user={user} />} />
            <Route path="/recharge-request" render={(props) => <RechargeRequest {...props} user={user} />} />
            <Route path="/reseller/wallet-view/:userId" render={(props) => <WalletView {...props} user={user} />} />

            {/* Users */}
            <Route path="/users" render={(props) => <Users {...props} user={user} />} />
            <Route path="/pending-users" render={(props) => <PendingUsers {...props} user={user} />} />
            <Route path="/user-details/:id" render={(props) => <UserDetails {...props} user={user} />} />

            {/* Top Up */}
            <Route path="/top-up" render={(props) => <TopUp {...props} user={user} />} />
            <Route path="/topup-transactions" render={(props) => <TopUpTransactions {...props} user={user} />} />
            <Route path="/order/:id" render={(props) => <TopUpInvoice {...props} user={user} />} />

            {/* Commissions */}
            <Route path="/routing-and-pricelist" render={(props) => <Commissions {...props} user={user} />} />

            {/* Vendors */}
            <Route path="/vendors" render={(props) => <Vendors {...props} user={user} />} />

            {user?.user?.user?.user?.userModel?.is_admin ?
                <Route path="/support-info" render={(props) => <SupportInfo {...props} user={user} />} /> :
                <Route path="/support-info" render={(props) => <SupportInfoForReseller {...props} user={user} />} />
            }
        </Switch>
    )
}

export default Layout1Route